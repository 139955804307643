<template>
  <div class="face-zone-single-select flex flex-column flex--align-center">
    <question-title class="q-mb16 q-mt0">{{ $t(title) }}</question-title>
    <div
      class="options"
      role="radiogroup"
      data-question
      tabindex="-1"
      :aria-label="$t(title)"
      @keydown.down.prevent="onKeyboardNavigation('down')"
      @keydown.up.prevent="onKeyboardNavigation('up')"
    >
      <div
        v-for="option of options"
        :key="option.value"
        class="options__option q-mb8 full-width flex flex-column flex--align-center"
      >
        <q-radio-button
          ref="option"
          :checked="option.value === value"
          :ai-selected="option.aiSelected"
          :dysfunction="dysfunction"
          :tabindex="getOptionTabIndex(option)"
          @change="onInput(option.value)"
        >
          <span class="options__question-value">{{ $t(option.text) }}</span>
        </q-radio-button>
      </div>
    </div>
    <face-zone-selection-picture
      class="q-mt24"
      :value="value"
      :gender="gender"
      :field-name="fieldName"
    />
  </div>
</template>

<script>
import QuestionTitle from '@/modules/questionnaire/new-design-components/questions/QuestionTitle';
import QRadioButton from '@/modules/questionnaire/new-design-components/inputs/QRadioButton';
import FaceZoneSelectionPicture from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneSelectionPicture';

import { COLOR_NAME } from '@/modules/questionnaire/new-design-components/questions/face-zone-input/faceZoneInput';
import { clamp } from 'ramda';

export default {
  name: 'FaceZoneSingleSelect',
  components: { QRadioButton, QuestionTitle, FaceZoneSelectionPicture },
  props: {
    title: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    /* zone number (0, 1, ...) */
    value: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    gender: {
      type: String,
      required: true
    }
  },
  computed: {
    dysfunction() {
      return COLOR_NAME[this.fieldName];
    },
    selectedOptionIndex() {
      return this.options.findIndex(option => option.value === this.value);
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onKeyboardNavigation(direction) {
      const increment = direction === 'down' ? 1 : -1;
      const index = clamp(0, this.options.length - 1, this.selectedOptionIndex + increment);

      this.$refs.option[index].$el.focus();
    },
    getOptionTabIndex(option) {
      if (!this.value) {
        return '0';
      }

      return option.value === this.value ? '0' : '-1';
    }
  }
};
</script>

<style lang="scss" scoped>
.face-zone-single-select {
  width: 100%;
}
</style>
