<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['greasySkinLocations'])">
      <face-zone-single-select
        title="label.greasySkinLocations"
        :gender="gender"
        :value="greasySkinLocations"
        :options="$options.greasySkinLocationsOptions"
        field-name="oiliness"
        @input="onFieldChange('greasySkinLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceZoneSingleSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneSingleSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { GREASY_SKIN_LOCATION_OPTIONS } from '@/modules/questionnaire/constants/steps/skinOiliness';

export default {
  name: 'OilinessLocationTemplate',
  components: { QuestionWithErrorWrap, FaceZoneSingleSelect },
  greasySkinLocationsOptions: GREASY_SKIN_LOCATION_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    gender: {
      type: String,
      required: true
    },
    greasySkinLocations: {
      type: String,
      default: ''
    }
  }
};
</script>
